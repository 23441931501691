
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div class="header-logo"></div>

        <article class="title-section">
          <h1>We are launching <span class="soon">soon!</span></h1>
          <p class="title-info">Subscribe and get notified</p>
        </article>

          <article class="input-section">
            <section class="input-block">
            <form class="submit-form" action="https://api.web3forms.com/submit" method="POST">
              <input type="hidden" name="access_key" value="507139dc-e904-418b-a8f9-b2fce3cbfd75" />
              <input type="hidden" name="first_name" value="new_user" />
              <input id="email-input" class="email-input" type="email" name="email" placeholder="Your email address.." />
              <button class="submit-btn">Notify Me</button>
            </form> 
            </section>
            
          </article>
          <article>

          <div class="statistics-img"></div>
        </article>
      </header>
    </div>
  );
}

export default App;
